import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentAvatar.figmaUrl.android} codeUrl={checklists.componentAvatar.codeUrl.android} checklists={checklists.componentAvatar.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <p>{`An avatar is a graphical representation of an object or entity, such as a person or organization.`}</p>
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one",
        "align": "center"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "align": "center",
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/avatar/android-compose-avatar.gif",
            "alt": "Legion Avatar Android Compose"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <div className="divi" />
    <h2>{`Variants and Sizes`}</h2>
    <p>{`Legion has 6 variants of Avatar`}</p>
    <ol>
      <li parentName="ol">{`Avatar with Badge Dot`}</li>
      <li parentName="ol">{`Avatar with Badge Icon`}</li>
      <li parentName="ol">{`Avatar with Badge Number`}</li>
      <li parentName="ol">{`Avatar Image Url`}</li>
      <li parentName="ol">{`Avatar Initial Text`}</li>
      <li parentName="ol">{`Avatar Image Icon`}</li>
    </ol>
    <p>{`Legion also has 3 sizes of Avatar`}</p>
    <ol>
      <li parentName="ol">{`Avatar Large`}</li>
      <li parentName="ol">{`Avatar Medium`}</li>
      <li parentName="ol">{`Avatar Small`}</li>
    </ol>
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`To implement Avatar, you can follow the this code.`}</p>
    <h3>{`With Badge Dot`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LgnBadgeBox(
    badge = {
        LgnBadgeDot(size = LgnBadgeSize.Medium, border = LgnBorderStroke(3.dp, Color.White))
    }
) {
    LgnAvatarInitials(initialText = "Telkom", size = LgnAvatarSize.Large)
}
`}</code></pre>
    <h3>{`With Badge Icon`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LgnBadgeBox(
    badge = {
        LgnBadge(size = LgnBadgeSize.Medium, border = LgnBorderStroke(3.dp, Color.White)) {
            Icon(imageVector = Icons.Default.AccountCircle, contentDescription = "")
        }
    }
) {
    LgnAvatarInitials(initialText = "Telkom", size = LgnAvatarSize.Large)
}
`}</code></pre>
    <h3>{`With Badge Number`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LgnBadgeBox(
    badge = {
        LgnBadge(size = LgnBadgeSize.Medium, border = LgnBorderStroke(3.dp, Color.White)) {
            Icon(imageVector = Icons.Default.AccountCircle, contentDescription = "")
        }
    }
) {
    LgnAvatarInitials(initialText = "Telkom", size = LgnAvatarSize.Large)
}
`}</code></pre>
    <h3>{`Attaching Image`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LgnBadgeBox(
    badge = {
        LgnBadgeDot(size = LgnBadgeSize.Medium, border = LgnBorderStroke(3.dp, Color.White))
    }
) {
    LgnAvatarImage(URL = "https://i.pravatar.cc/40", avatarSize = LgnAvatarSize.Large)
}
`}</code></pre>
    <h3>{`With Initial Text`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LgnAvatarInitials((initialText = "Telkom"), (size = LgnAvatarSize.Large));
`}</code></pre>
    <h3>{`With Icon`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LgnAvatarIcon(
  (painter = painterResource((id = R.drawable.ic_account))),
  (size = LgnAvatarSize.Large)
);
`}</code></pre>
    <h2>{`Sizes`}</h2>
    <h3>{`Large Size`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LgnAvatarInitials((initialText = "Telkom"), (size = LgnAvatarSize.Large));
`}</code></pre>
    <h3>{`Medium Size`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LgnAvatarInitials((initialText = "Telkom"), (size = LgnAvatarSize.Medium));
`}</code></pre>
    <h3>{`Small Size`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LgnAvatarInitials((initialText = "Telkom"), (size = LgnAvatarSize.Small));
`}</code></pre>
    <div className="divi" />
    <h2>{`Attributes`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameters`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`initialText`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Initial Text of Avatar`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`size`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`LgnAvatarSize`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Avatar Size`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`containerColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Avatar Color`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`contentColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Avatar Description Content`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`contentDescription`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Avatar Image URL`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`URL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Avatar Resource`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`resource`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Int`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Avatar Drawable Resource`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      